.nav {
  @apply bg-white z-30 w-full shadow-custom;

  .inner {
    @apply px-4 py-4 flex justify-between items-center;

    .left {
      @apply justify-start flex;
    }

    .right {
      @apply justify-end;
    }
  }
}
