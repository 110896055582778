.container {
  @apply pb-12;

  .containerInner {
    @apply max-w-6xl mx-auto px-4 sm:px-6;

    .upper {
      @apply sm:flex sm:items-center sm:justify-between;

      .list {
        @apply flex flex-wrap items-center mb-6 text-sm font-medium text-gray-600 sm:mb-0 mt-4 md:mt-0;

        .element {
          @apply ms-4 md:ms-6;
        }
      }
    }

    .divider {
      @apply my-4 border-gray-600 sm:mx-auto lg:my-6;
    }

    .lower {
      @apply sm:flex sm:items-center sm:justify-between;

      .copyright {
        @apply text-sm text-gray-600 sm:text-center;
      }

      .social {
        @apply flex mt-4 sm:justify-center sm:mt-0;

        .element {
          @apply ms-5;
        }
      }
    }
  }
}
