/* Data grid */
.MuiDataGrid-root {
  @apply !rounded-2xl !border !border-gray-400;

  &.rows-clickable {
    .MuiDataGrid-row {
      @apply !cursor-pointer;
    }
  }

  .MuiDataGrid-virtualScroller {
    .MuiDataGrid-row {
      @apply hover:!bg-gray-100;
    }
  }

  div {
    @apply !outline-none !ring-0;
  }

  .Mui-selected {
    @apply !bg-green-100;
  }

  .MuiButtonBase-root {
    @apply !text-green-400;
  }

  .MuiDataGrid-columnHeaders {
    @apply bg-gray-300 rounded-t-2xl;
  }
}

/* Switch */
.MuiSwitch-root {
  .MuiSwitch-switchBase {
    @apply !text-green-400;
  }

  .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track {
    @apply !bg-gray-600 !opacity-80;
  }

  .Mui-checked.Mui-checked + .MuiSwitch-track {
    @apply !bg-green-300;
  }
}
/* Button */
.MuiButton-root {
  @apply !text-green-400 hover:!bg-gray-300;

  &.MuiButton-containedPrimary {
    @apply !bg-green-400 hover:!bg-green-500 !text-white !shadow-none;
  }

  &.MuiButton-containedSecondary {
    @apply !bg-yellow-500 hover:!bg-yellow-600 !text-white !shadow-none;
  }
}

/* Input */
.MuiFormLabel-root {
  @apply !text-gray-800;
}

/* Checkbox */
.MuiCheckbox-root {
  svg {
    @apply !text-green-400;
  }
}

/* Progress */
.MuiCircularProgress-root {
  @apply !text-green-400;
}
