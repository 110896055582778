.container {
  @apply block p-4 bg-white border border-gray-400 rounded-2xl;

  .content {
    @apply space-y-4;

    .title {
      @apply text-lg font-semibold;
    }

    .description {
      @apply overflow-hidden line-clamp-2;
    }

    .footer {
      @apply text-sm font-light;

      .duration {
        @apply text-xs;
      }
    }
  }
}
