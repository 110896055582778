.label {
  @apply flex items-center;

  .input {
    @apply rounded-md;
  }

  .labelText {
    @apply text-gray-600 ml-2;
  }
}

.error {
  @apply text-red-500 text-sm mt-0;
}
