.button {
  @apply flex items-center text-sm pe-1 font-medium text-gray-900 rounded-full hover:text-green-600 md:me-0 ring-0;

  .initials {
    @apply relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full mr-2;

    .text {
      @apply font-medium text-gray-600;
    }
  }

  .name {
    @apply hidden sm:block;
  }

  .svg {
    @apply w-2.5 h-2.5 ms-3;
  }
}

.dropdownMenu {
  @apply fixed z-50 bg-white divide-y divide-gray-100 rounded-lg shadow w-44;

  .emailContainer {
    @apply px-4 py-3 text-sm;

    .emailContainerText {
      @apply truncate hover:break-all hover:overflow-visible hover:whitespace-normal;
    }
  }

  .middleSection {
    @apply py-2 text-sm;

    .linkElement {
      @apply text-gray-700;
    }
  }

  .endSection {
    @apply py-2 text-sm;

    .linkElement {
      @apply text-gray-600;
    }
  }

  .linkElement {
    @apply block px-4 py-2 hover:bg-green-400 hover:text-white;
  }
}
