.link {
  @apply hover:text-gray-800 cursor-pointer;
}

.default {
  @apply text-green-400;
}

.error {
  @apply text-red-500;
}

.disabled {
  @apply text-gray-600 cursor-text hover:text-gray-600;
}

.secondary {
  @apply text-gray-600;
}
