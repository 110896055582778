.container {
  @apply w-full flex items-center;

  .ol {
    @apply inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse;

    .entry {
      @apply inline-flex items-center;

      .link {
        @apply inline-flex items-center font-medium text-sm gap-2;
      }
    }

    .chevron {
      @apply rtl:rotate-180 w-3 h-3 text-gray-400 ml-1 md:ml-2;
    }
  }
}
