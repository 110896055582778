@import '../../node_modules/react-toastify/dist/ReactToastify.css';

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: var(--color-gray-900);
  --toastify-color-info: var(--color-yellow-500);
  --toastify-color-success: var(--color-green-400);
  --toastify-color-warning: var(--color-yellow-800);
  --toastify-color-error: var(--color-red-500);
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: Inter;
  --toastify-z-index: 9999;

  --toastify-text-color-light: var(--color-gray-800);
  --toastify-text-color-dark: var(--color-white);

  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: var(--color-gray-600);
  --toastify-spinner-color-empty-area: #e0e0e0;

  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );

  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);

  --toastify-color-progress-bgo: 0.2;
}
