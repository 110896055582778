.header {
  @apply text-xl font-bold leading-tight md:text-2xl;
}

.error {
  @apply text-red-500 text-sm;
}

.bottomText {
  @apply text-sm font-light text-gray-500;
}
