.header {
  @apply text-xl font-bold leading-tight md:text-2xl;
}

.form {
  @apply space-y-4 md:space-y-6;
}

.error {
  @apply text-red-500 text-sm;
}
