.container {
  @apply bg-white border border-gray-400 rounded-2xl relative flex flex-col;

  .image {
    @apply rounded-t-2xl h-64 object-cover w-full;
  }

  .content {
    @apply p-5 flex flex-col flex-1;

    .title {
      @apply mb-2 text-2xl font-bold tracking-tight text-gray-800;
    }

    .text {
      @apply mb-4 font-normal text-gray-600 line-clamp-3 mt-auto;
    }

    .button {
      @apply w-fit;

      .svg {
        @apply rtl:rotate-180 w-3.5 h-3.5 ms-2;
      }
    }
  }
}
