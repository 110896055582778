.container {
  @apply absolute w-full left-0 flex items-center justify-center bottom-8 animate-bounce;

  .button {
    @apply items-center justify-center w-12 h-12 mr-2 bg-none rounded-full hidden md:inline-flex;

    .svg {
      @apply text-gray-800;
    }
  }
}
