.container {
  @apply flex flex-col h-full;

  .actions {
    @apply mb-4 block md:flex justify-center items-center space-y-4 md:space-y-0 space-x-0 md:space-x-4;

    .search {
      @apply flex-1;
    }

    .createButton {
      @apply max-w-full md:max-w-60;
      max-height: 42px;
    }
  }

  .table {
    @apply w-full h-full;
  }
}

.offline {
  @apply text-red-500;
}

.online {
  @apply text-green-400;
}

.error {
  @apply text-red-500 text-sm mb-4;
}
