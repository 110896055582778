@import 'tailwindcss/base';
@import 'tailwindcss/components';

/* Some styles from the OPEN PRO template */
@import './styles/theme.css';
@import './styles/toggle-switch.css';
@import './styles/utility-pattern.css';
@import './styles/mui-overrides.scss';
@import './styles/toastify.css';

@import 'tailwindcss/utilities';

/* React grid layout */
@import '../node_modules/react-grid-layout/css/styles.css';
@import '../node_modules/react-resizable/css/styles.css';
@import './styles/react-grid-layout-branding.css';

/* AOS */
@import 'aos/dist/aos.css';

body {
  @apply font-inter antialiased bg-white text-gray-800 tracking-tight;
}
