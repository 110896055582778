.button {
  @apply text-white w-full rounded-xl disabled:cursor-not-allowed;

  &.primary {
    @apply bg-green-400 hover:bg-green-500 disabled:hover:bg-green-400;
  }

  &.secondary {
    @apply bg-yellow-500 hover:bg-yellow-600 disabled:hover:bg-yellow-500;
  }

  .loaderContainer {
    @apply h-full w-full;
  }

  &.buttonSm {
    @apply text-sm px-4 py-2;
  }

  &.buttonMd {
    @apply px-8;
  }
}
