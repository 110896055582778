.container {
  @apply h-full w-full flex flex-col;

  .description {
    @apply mb-4;
  }

  .content {
    @apply flex-1 overflow-y-auto space-y-4;
  }

  .footer {
    @apply mt-4 block space-y-4 md:flex md:space-y-0 md:gap-4;
  }
}
