.container {
  &.fullHeight {
    @apply min-h-screen flex flex-col justify-center;
  }

  &.notFullHeight {
    @apply pb-12 md:pb-20 lg:pt-36;
  }

  .inner {
    @apply max-w-3xl mx-auto text-center;

    .title {
      @apply mb-10;
    }

    .subtitle {
      @apply text-xl text-gray-600 mb-10;
    }
  }
}
