.container {
  @apply flex flex-col h-screen overflow-hidden;

  .containerInner {
    @apply flex flex-grow overflow-hidden bg-gray-300;

    .content {
      @apply w-full p-4 overflow-y-auto;
    }
  }
}
