.inputContainer {
  @apply relative;

  .input {
    @apply block w-full rounded-xl text-gray-700 placeholder-gray-400;

    &.inputError {
      @apply border-red-500 focus:border-red-500 focus:ring-red-500;
    }

    &.inputSuccess {
      @apply focus:border-green-400 focus:ring-green-400;
    }

    &.inputWithIcon {
      @apply ps-9;
    }
  }

  .leftIconContainer {
    @apply absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none;

    .icon {
      @apply w-4 h-4 text-gray-500;
    }
  }

  .rightIconContainer {
    @apply absolute inset-y-0 end-0 flex items-center pe-3;

    .icon {
      @apply w-4 h-4 text-gray-800 hover:text-gray-900;
    }
  }
}
