.loginHeader {
  @apply text-xl font-bold leading-tight md:text-2xl;
}

.loginForm {
  @apply space-y-4 md:space-y-6;

  .loginFormLowerSection {
    @apply md:flex items-center justify-between;

    .rememberMe {
      @apply flex mb-2 md:mb-0 items-start;
    }
  }

  .errorText {
    @apply text-red-500 text-sm;
  }

  .registerText {
    @apply text-sm font-light text-gray-500;
  }
}
