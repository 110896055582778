.container {
  @apply flex flex-col min-h-screen;

  .content {
    @apply flex-1;

    .contentInner {
      @apply max-w-6xl mx-auto px-4 sm:px-6;
    }
  }
}
