.header {
  @apply text-xl font-bold leading-tight md:text-2xl;
}

.loader {
  @apply w-full items-center justify-center flex;
}

.error {
  @apply text-red-500 text-sm;
}
