.header {
  @apply text-xl font-bold leading-tight md:text-2xl;
}

.form {
  @apply space-y-4 md:space-y-6;

  .formNames {
    @apply grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6;

    .formName {
      @apply w-full;
    }
  }

  .error {
    @apply text-red-500 text-sm;
  }

  .bottomText {
    @apply text-sm font-light text-gray-500;
  }
}
