.container {
  @apply p-4;

  .sideNav {
    @apply bg-green-400 w-64 h-full rounded-2xl py-4 text-white relative;

    .entry {
      @apply p-4 w-full hover:bg-gray-300 cursor-pointer text-white hover:text-green-500 max-w-64;

      .link {
        @apply flex;

        .icon {
          @apply me-2 flex items-center text-lg;
        }
      }
    }

    .footer {
      @apply absolute bottom-0 left-0 right-0 p-2 w-full text-center text-xs;
    }
  }
}
