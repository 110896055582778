.container {
  @apply w-full h-full bg-white rounded-2xl border border-gray-400 p-4 flex flex-col overflow-y-auto;

  .titleContainer {
    @apply mb-4;

    .title {
      @apply text-2xl font-semibold;
    }
  }

  .contentContainer {
    @apply h-full overflow-hidden;
  }
}
