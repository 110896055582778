.container {
  @apply mb-10;

  &.noMarginBottom {
    @apply mb-0;
  }

  .titleContainer {
    @apply w-full text-center mb-4;
  }

  .recommendationsContainer {
    @apply w-full mb-4 grid gap-4;

    &.mdCols1 {
      @apply md:grid-cols-1;
    }
    &.mdCols2 {
      @apply md:grid-cols-2;
    }
    &.mdCols3 {
      @apply md:grid-cols-3;
    }
    &.mdCols4 {
      @apply md:grid-cols-4;
    }
    &.lgCols1 {
      @apply lg:grid-cols-1;
    }
    &.lgCols2 {
      @apply lg:grid-cols-2;
    }
    &.lgCols3 {
      @apply lg:grid-cols-3;
    }
    &.lgCols4 {
      @apply lg:grid-cols-4;
    }
  }

  .subtextContainer {
    @apply w-full text-center text-sm;
  }
}
