.container {
  @apply flex flex-col w-full h-full;

  .breadcrumbs {
    @apply mb-4;
  }

  .gridLayoutContainer {
    @apply w-full h-full;
  }
}
