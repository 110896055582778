.toggleElement {
  @apply inline-flex items-center cursor-pointer;

  .toggle {
    @apply relative w-11 h-6 bg-gray-300 rounded-full after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all;
  }

  .text {
    @apply ms-3 text-sm font-medium text-gray-800;
  }
}

.toggleContainer {
  @apply -mb-2;
}
