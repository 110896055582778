.label {
  @apply block text-gray-700 text-sm font-medium mb-1;

  .error {
    @apply text-red-500;
  }
}

.error {
  @apply text-red-500 text-sm mt-2;
}

.description {
  @apply mt-2 text-sm text-gray-500;
}
