.controls {
  @apply mb-10;

  .controlGrid {
    @apply grid grid-cols-1 md:grid-cols-2 gap-4;
  }
}

.subtext {
  @apply text-gray-600;
}
