.container {
  @apply flex flex-col items-center md:justify-center px-6 py-6 mx-auto min-h-screen bg-gray-200;

  .logo {
    @apply mb-6;
  }

  .containerContent {
    @apply w-full rounded-2xl md:mt-0 sm:max-w-lg xl:p-0 bg-white shadow;

    .containerContentInner {
      @apply p-6 space-y-4 md:space-y-6 sm:p-8;
    }
  }
}
