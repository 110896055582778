.header {
  @apply w-full z-30 fixed;

  .container {
    @apply max-w-6xl mx-auto sm:px-6;

    .containerInner {
      @apply bg-white px-5 lg:rounded-xl lg:shadow-custom lg:mt-5 lg:-mx-10;

      .content {
        @apply flex items-center justify-between h-20;

        .desktop {
          @apply hidden lg:flex lg:grow;

          .menu {
            @apply flex grow justify-end flex-wrap items-center;

            .link {
              @apply px-4 py-2 flex items-center;
            }
          }

          .userContainer {
            @apply pr-4 pl-8 py-2;
          }
        }

        .mobile {
          @apply lg:hidden flex;

          .userContainer {
            @apply mr-8;
          }

          .hamburgerIcon {
            @apply w-6 h-6 fill-current text-gray-700 hover:text-gray-800 transition duration-150 ease-in-out;
          }

          .nav {
            @apply absolute top-full z-20 left-0 w-full overflow-hidden transition-all duration-300 ease-in-out;

            .menu {
              @apply bg-gray-200 px-4 py-2;

              .link {
                @apply px-4 py-2 flex items-center transition duration-150 ease-in-out;
              }
            }
          }
        }
      }
    }
  }
}
