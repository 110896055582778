.input {
  @apply block w-full rounded-xl text-gray-700 placeholder-gray-400;

  &.inputError {
    @apply border-red-500 focus:border-red-500 focus:ring-red-500;
  }

  &.inputSuccess {
    @apply focus:border-green-400 focus:ring-green-400;
  }
}
